
.AttendanceAcceptPopup {
    max-width: 570px;
    width: 90vw;
  
    display: block ;
    align-items: center;
    margin: auto;
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  
  .AttendanceAcceptPopup table
  {
    width: 100%; 
  }
  
  .AttendanceAcceptPopupCaption
  {
    width: 20%;
  }
  .AttendanceAcceptPopupValue
  {
    width: 80%;
    font-weight: bold;
    text-align: right;
  }