.LiveScreen {
    background: rgb(0, 255, 0);
    width: 1920px;
    height: 1080px;
}

.live-overlay-enter {
    opacity: 0;
}

.live-overlay-enter-active {
    opacity: 1;
    transition: opacity 1200ms;
}

.live-overlay-exit {
    opacity: 1;
}

.live-overlay-exit-active {
    opacity: 0;
    transition: opacity 1200ms;
}

.LiveScreen .LiveBackground {
    position: absolute;
    left: 0px;
    top: 0px;
}

.LiveScreen .LiveText {
    color: white;
    font-size: 48px;
    font-weight: bold;
}

.LiveScreen .LiveTextSmall {
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.LiveScreen .LeftDriver {
    position: absolute;
    left: 140px;
    top: 960px;
    width: 200px;
    text-align: center;
    /* background: red; */
}

.LiveScreen .LeftDriverName {
    position: absolute;
    left: 370px;
    top: 955px;
    width: 520px;
    text-align: center;
    /* background: red; */
}

.LiveScreen .LeftDriverCar {
    position: absolute;
    left: 370px;
    top: 995px;
    width: 520px;
    text-align: center;
    /* background: red; */
}


.LiveScreen .RightDriver {
    position: absolute;
    left: 940px;
    top: 960px;
    width: 520px;
    text-align: center;
    /* background: red; */
}

.LiveScreen .RightDriverName {
    position: absolute;
    left: 1330px;
    top: 955px;
    width: 520px;
    text-align: center;
    /* background: red; */
}

.LiveScreen .RightDriverCar {
    position: absolute;
    left: 1330px;
    top: 995px;
    width: 520px;
    text-align: center;
    /* background: red; */
}

.LiveScreen .LeftEt {
    position: absolute;
    left: 140px;
    top: 600px;
    width: 200px;
    overflow: hidden;
    text-align: center;
    /* background: red; */
}

.LiveScreen .RightEt {
    position: absolute;
    left: 1670px;
    top: 600px;
    width: 200px;
    overflow: hidden;
    text-align: center;
    /* background: red; */
}

.LiveScreen .LeftSpeed {
    position: absolute;
    left: 140px;
    top: 720px;
    width: 200px;
    overflow: hidden;
    text-align: center;
    /* background: red; */
}

.LiveScreen .RightSpeed {
    position: absolute;
    left: 1670px;
    top: 720px;
    width: 200px;
    overflow: hidden;
    text-align: center;
    /* background: red; */
}

.LiveScreen .LeftRt {
    position: absolute;
    left: 140px;
    top: 840px;
    width: 200px;
    overflow: hidden;
    text-align: center;
    /* background: red; */
}

.LiveScreen .RightRt {
    position: absolute;
    left: 1670px;
    top: 840px;
    width: 200px;
    overflow: hidden;
    text-align: center;
    /* background: red; */
}

.LiveScreen .LiveLeftPicture 
{
    position: absolute;
    left: 500px;
    top: 600px;
    max-width: 280px;
    max-height: 320px;
    width: auto;
    height: auto;
    overflow: hidden;
    text-align: center;
}

.LiveScreen .LiveRightPicture 
{
    position: absolute;
    left: 1280px;
    top: 600px;
    max-width: 280px;
    max-height: 320px;
    width: auto;
    height: auto;
    overflow: hidden;
    text-align: center;
}

.LogoContainer {
    display: contents;
}

.SponsorLogo {
    position: absolute;
    width: auto;
    max-width: 650px;
    height: 300px;
    object-fit:scale-down;
    top: 15px;
    left: 15px;
}

@keyframes slide-in {
    100% {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slide-out {
    100% {
        transform: translateX(200%);
        opacity: 0;
    }
}

.slide-enter {
    opacity: 0;
    transform: translateX(-100%);
}

.slide-enter-active {
    animation: slide-in 2.5s forwards;
}


.slide-exit {
    opacity: 1;
}

.slide-exit-active {
    animation: slide-out 2.5s forwards;
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 1600ms ease-in;
}


.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 700ms ease-out;
}

.viewOptions {
    position: relative;
    text-align: center;
    width: 600px;
    margin-left: auto;
    margin-right: auto;
    top: 50%;
    transform: translateY(-50%);
    font-size: 50px;
}

.Option-Selected {
    width: 600px;
    background-image: linear-gradient(to right, rgb(60, 150, 60), rgba(4, 20, 4, 0.856));
    color: white;
    margin-top: 6px;
    border-radius: 5px;
    border: 2px solid rgb(10, 46, 1) !important;
    cursor: pointer;
}

.Option-Selected:active {
    transform: translateY(4px);
}

.Option-Unselected {
    width: 600px;
    color: white;
    background-image: linear-gradient(to right, red, rgba(94, 8, 12, 0.801));
    margin-top: 6px;
    border-radius: 5px;
    border: 2px solid rgb(49, 1, 1) !important;
    cursor: pointer;
}

.Option-Unselected:active {
    transform: translateY(4px);
}

.saveButton {
    margin-top: 6px;
    width: 600px;
    background-image: linear-gradient(to right, white, rgba(194, 200, 204, 0.637));
    border-radius: 5px;
    border: 1px solid black !important;
    cursor: pointer;
    color: black;
    border-radius: 5px;
}

.saveButton:active {
    background-color: white;
    transform: translateY(4px);
}

.showPrevViewButton {
    margin-top: 15px;
    margin-left: 15px;
    font-size: 30px;
    border-radius: 5px;
    border: 1px solid black !important;
    background-image: linear-gradient(to right, white, rgba(194, 200, 204, 0.637));
    cursor: pointer;
}

.showPrevViewButton:active {
    background-color: white;
    transform: translateY(4px);
}

.LivePrestage
{
    width: 1920px;
    height: 1080px;
}

.PrestageLeft
{
    position: absolute;
    left: 20px;
    top: 300px;

    width: 600px;
    height: auto;
    display: flex;
    flex-direction: column;
}

.PrestageLeftItem
{
    width: auto;
    height: auto;
    position: relative;
}
.PrestageLeftPicture
{
    position: absolute;
    left: 650px;
    top: 50px;
    width: 220px;
    height: 220px;
    object-fit: contain;
}


.PrestageRight
{
    position: absolute;
    left: 1000px;
    top: 300px;

    width: 900px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.PrestageRightItem
{
    width: auto;
    height: auto;
    position: relative;
}
.PrestageRightPicture
{
    position: absolute;
    left: 1075px;
    top: 50px;
    width: 220px;
    height: 220px;
    object-fit: contain;
}

.PrestageItemBackground
{

}

.PrestageText
{
    position: absolute;
    left: 130px;
    top: 35px;
    width: 370px;
    color: white;
    text-align: center;
    font-size: 25px;
    font-weight: bold;
}

.PrestageTextSmall
{
    font-size: 20px;
}

.PrestageCaption
{
    position: absolute;
    left: 160px;
    top: 15px;
    width: 370px;
    color: white;
    text-align: left;
    font-size: 17px;
    font-weight: normal;
}

.PrestageLeftDriver
{
    position: relative;
    width: auto;
    height: 110px;
}

.PrestageLeftDriverNumber
{    
    position: absolute;
    left: 180px;
    top: 23px;
    color: white;
    font-size: 37px;
    font-weight: bold;
}

.PrestageLeftDriverName
{    
    position: absolute;
    width: 300px;
    left: 450px;
    top: 3px;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.PrestageLeftDriverCar
{    
    position: absolute;
    width: 300px;
    left: 430px;
    top: 43px;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.PrestageLeftTeamContainer
{
    position: relative;
    width: 300px;
}

.PrestageLeftTeam
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageLeftClassContainer
{
    position: relative;
    width: 300px;
}

.PrestageLeftClass
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageLeftMassContainer
{
    position: relative;
    width: 300px;
}

.PrestageLeftMass
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageLeftPowerContainer
{
    position: relative;
    width: 300px;
}

.PrestageLeftPower
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageLeftEventBestContainer
{
    position: relative;
    width: 300px;
}

.PrestageLeftEventBestEt
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageLeftEventBestSpeed
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 60px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageLeftSeasonBestContainer
{
    position: relative;
    width: 300px;
}

.PrestageLeftSeasonBestEt
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageLeftSeasonBestSpeed
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 60px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}


.PrestageRightDriver
{
    position: relative;
    width: auto;
    height: 110px;
}

.PrestageRightDriverNumber
{    
    position: absolute;
    left: 180px;
    top: 23px;
    color: white;
    font-size: 37px;
    font-weight: bold;
}

.PrestageRightDriverName
{    
    position: absolute;
    width: 300px;
    left: 450px;
    top: 3px;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.PrestageRightDriverCar
{    
    position: absolute;
    width: 300px;
    left: 430px;
    top: 43px;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.PrestageRightTeamContainer
{
    position: relative;
}

.PrestageRightTeam
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageRightClassContainer
{
    position: relative;
}

.PrestageRightClass
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageRightMassContainer
{
    position: relative;
}

.PrestageRightMass
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageRightPowerContainer
{
    position: relative;
}

.PrestageRightPower
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageRightEventBestContainer
{
    position: relative;
}

.PrestageRightEventBestEt
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageRightEventBestSpeed
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 60px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageRightSeasonBestContainer
{
    position: relative;
}

.PrestageRightSeasonBestEt
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.PrestageRightSeasonBestSpeed
{
    position: absolute;
    width: 500px;
    left: 180px;
    top: 60px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.LiveResultWrapper
{
    position: absolute;  
    left: 400px;
    top: 150px;

    height: 940px;

    /* overflow-y: hidden; */
}

.LiveResultSummary
{
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 10px;
    scale: 0.8;

}

.LiveResultsData
{    
    overflow: hidden;
    height: 940px;
}

.LiveResultsScroll
{
    /* animation: result-rows 6s ease-in-out;
    animation-delay: 4s; */
}

@keyframes result-rows {
    0% {
        transform:translateY(0);
     }
     100% {
        transform:translateY(-50%);
     }
  }

.LiveResultsRow
{
    position: relative;
    width: 1400px;
    margin-top: 5px;
}


.LiveResultsHeader
{
    position: relative;
    width: 820px;
    margin-bottom: 40px;
}

.LiveResultsHeader .LiveResultsHeaderClassName
{
    position: absolute;
    width: 500px;
    left: 200px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.LiveResultsHeader .LiveResultsHeaderTimestamp
{
    position: absolute;
    width: 200px;
    left: 700px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.LiveResultsRow .LiveResultsRowBackground
{
    top: 0px;
    left: 0px;
}

.LiveResultsRow .LiveResultsRowBackgroundEt
{
    position: absolute;
    top: -5px;
    left: 890px;
}

.LiveResultsRow .LiveResultsRowDriverNumber
{    
    position: absolute;
    left: 180px;
    top: 23px;
    color: white;
    font-size: 37px;
    font-weight: bold;
}

.LiveResultsRow .LiveResultsRowDriverName
{    
    position: absolute;
    width: 500px;
    left: 450px;
    top: 3px;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.LiveResultsRow .LiveResultsRowDriverCar
{    
    position: absolute;
    width: 500px;
    left: 430px;
    top: 43px;
    color: white;
    font-size: 30px;
    font-weight: bold;
}

.LiveResultsRow .LiveResultsRowBestEt
{
    position: absolute;
    width: 500px;
    left: 1080px;
    top: 25px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}

.LiveResultsRow .LiveResultsRowBestSpeed
{
    position: absolute;
    width: 500px;
    left: 1080px;
    top: 60px;
    color: white;
    font-size: 26px;
    font-weight: bold;
}