.HelpIcon {
    width: auto;
    height: auto;
    border-style: solid;
    border: 1px;
    border-color: #d7d7d7;
    cursor: pointer;
    display: flex;
    flex-direction: row;

    align-items: center;
    position: relative;
}

.HelpIcon-Position-above-right {
  position: absolute;
  bottom: 100%;
  left: 40px;
  z-index: 10;
}

.HelpIcon-Position-above-left {
  position: absolute;
  bottom: 100%;
  right: 40px;
  z-index: 10;
}

.HelpIcon-Position-below-right {
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 40px;
  margin-top: -10px;
}

.HelpIcon-Position-below-left {
  position: absolute;
  z-index: 10;
  top: 100%;
  right: 40px;
  margin-top: -10px;
}
.HelpIcon-Tooltip {
    text-align: right;
    flex-basis: auto;
    width: fit-content;
    flex: 1;
    box-shadow: none;
    background: #333436;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: transparent !important;
}

.show-enter {
  opacity: 0.01;
}

.show-enter.show-enter-active {
  opacity: 1;
  transition: opacity 300ms ease-in;
}

.show-leave {
  opacity: 1;
}

.show-leave.show-leave-active {
  opacity: 0.01;
  transition: opacity 170ms ease-in;
}

.HelpIcon #HI-Tooltip{   overflow: visible;  width: 100%;   list-style-type: none; padding-inline-start: 0px; }
.HelpIcon #HI-Tooltip li{  float: left; }
.HelpIcon #HI-Tooltip .above {
  padding: 6px 15px 6px 16px;
  float: left; text-align:center;
  position: relative;
  color: #777;
  background: #f5f5f5;  min-width:81px; width:auto;
  box-shadow: 3px 3px 6px rgba(0,0,0,0.6);
  font-size: 12px;
  min-width: 150px;
}

.HelpIcon #HI-Tooltip .below {
  padding: 4px 10px 4px 12px;
  float: left; text-align:center;
  color: #777;
  background: #f5f5f5;  min-width:81px; width:auto;
  box-shadow: 3px 3px 6px rgba(0,0,0,0.6);
  font-size: 12px;
  min-width: 150px;
}

.HelpIcon .content {
  position: relative;
}

.HelpIcon .content .close {
  position: absolute;
  top: 0px;
  right: -5px;
  height: 10px;
  line-height: 10px;
}