.u-section-2 .u-sheet-1 {
    min-height: 543px;
  }
  
  .u-section-2 .u-layout-wrap-1 {
    margin: 60px auto 60px 0;
  }
  
  .u-section-2 .u-layout-cell-1 {
    min-height: 407px;
  }
  
  .u-section-2 .u-container-layout-1 {
    padding: 30px;
  }
  
  .u-section-2 .u-image-1 {
    height: 347px;
    margin: 0 auto 0 0;
  }
  
  .u-section-2 .u-layout-cell-2 {
    min-height: 96px;
  }
  
  .u-section-2 .u-container-layout-2 {
    padding: 30px;
  }
  
  .u-section-2 .u-text-1 {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .u-section-2 .u-layout-cell-3 {
    min-height: 311px;
  }
  
  .u-section-2 .u-container-layout-3 {
    padding: 30px;
  }
  
  .u-section-2 .u-text-2 {
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 0;
  }
  
  .u-section-2 .u-layout-cell-4 {
    min-height: 311px;
  }
  
  .u-section-2 .u-container-layout-4 {
    padding: 30px;
  }
  
  .u-section-2 .u-text-3 {
    margin-top: 0;
    margin-bottom: 0;
  }
  
  .u-section-2 .u-btn-1 {
    background-image: none;
    border-style: none none solid;
    padding: 0;
  }
  
  @media (max-width: 1199px) {
    .u-section-2 .u-sheet-1 {
      min-height: 472px;
    }
  
    .u-section-2 .u-layout-wrap-1 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-2 .u-layout-cell-1 {
      min-height: 336px;
    }
  
    .u-section-2 .u-image-1 {
      height: 275px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-2 .u-layout-cell-2 {
      min-height: 79px;
    }
  
    .u-section-2 .u-layout-cell-3 {
      min-height: 256px;
    }
  
    .u-section-2 .u-layout-cell-4 {
      min-height: 256px;
    }
  }
  
  @media (max-width: 991px) {
    .u-section-2 .u-sheet-1 {
      min-height: 436px;
    }
  
    .u-section-2 .u-layout-cell-1 {
      min-height: 772px;
    }
  
    .u-section-2 .u-image-1 {
      height: 716px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-2 .u-layout-cell-2 {
      min-height: 100px;
    }
  
    .u-section-2 .u-layout-cell-3 {
      min-height: 100px;
    }
  
    .u-section-2 .u-layout-cell-4 {
      min-height: 100px;
    }
  }
  
  @media (max-width: 767px) {
    .u-section-2 .u-sheet-1 {
      min-height: 536px;
    }
  
    .u-section-2 .u-layout-cell-1 {
      min-height: 579px;
    }
  
    .u-section-2 .u-container-layout-1 {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .u-section-2 .u-image-1 {
      height: 564px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-2 .u-container-layout-2 {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .u-section-2 .u-container-layout-3 {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .u-section-2 .u-container-layout-4 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
  
  @media (max-width: 575px) {
    .u-section-2 .u-layout-cell-1 {
      min-height: 365px;
    }
  
    .u-section-2 .u-image-1 {
      height: 347px;
      margin-right: initial;
      margin-left: initial;
    }
  }