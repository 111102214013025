.results-section .u-sheet-1 {
    min-height: 464px;
}
  
.results-section .u-sheet-events {
}

.results-section .u-table-1 {
    margin: 42px auto 42px 0;
}
  
.results-section .u-table-entity-1 {
    min-height: 100px;
    height: 325px;
}
  
.results-section .u-table-header-1 {
    font-weight: 700;
    background-image: none;
}
  
@media (max-width: 800px) {
    .results-section .u-table-1 {
        margin-right: initial;
        margin-left: initial;
    }
}

.results-header 
{
    margin-top: 1px !important;
    margin-bottom: 1px !important;
}

.results-table tr:nth-child(even) 
{
    background: lightgray !important
}

.results-order
{
    margin-left: 5px;
}

tr.result-row-invalid td:before {
    content: " ";
    position: absolute;
    top: 50%;
    left: 0;
    border-bottom: 1px solid #111;
    width: 100%;
  }
  
  tr.result-row-invalid td:after {
    content: "\00B7";
    font-size: 1px;
  }