#Tooltip
{   
  overflow: visible;  
  width: 100%;
  list-style-type: none;
  padding-inline-start: 40px;
  margin-block-start: 0px;
}
#Tooltip li{  float: left; }
#Tooltip .current{
  padding: 4px 10px 4px 12px;
  float: left; text-align:center;
  color: #fff2f9;
  position: relative; 
  background: #e31d2c;  min-width:81px; width:auto;
  font-size: 12px;
}

#Tooltip .current::after,
#Tooltip .current::before{
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -8px;   
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid;
  left: -8px;
}
#Tooltip .current::after{   z-index: 2;  border-right-color: #e31d2c;   }