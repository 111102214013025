.chat-container > .chat-message:nth-child(odd)
{
    background-image: linear-gradient(to bottom right, lightblue, lightgray);
}

.chat-avatar
{
    width:64px;
    height:64px
}

.chat-comment
{
    min-height: 100px;
    height: 5vw;
}

.chat-message
{
    display: flex;
    flex-direction: row;
}