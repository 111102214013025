.application-pending
{
  font-style: bold;  
}

.application-accepted
{
  font-style: italic;  
  text-decoration: line-through;  
}
.application-rejected
{
  font-style: italic;  
  text-decoration: line-through;  
}
.application-cancelled
{
  text-decoration: line-through;  
  font-style: italic;  
}

.actions
{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.AcceptPlayerPopup
{
  min-width: 70vw;
}

.PlayerNumberPopup {
  max-width: 1270px;
  width: 70vw;

  display: block ;
  align-items: center;
  margin: auto;
  background: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 40px;
  padding-bottom: 40px;

  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PlayerNumberPopup table
{
  width: 100%; 
}

.PlayerNumberPopupCaption
{
  width: 20%;
}
.PlayerNumberPopupValue
{
  width: 80%;
  font-weight: bold;
  text-align: right;
}