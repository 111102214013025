.PopupAlert {
    max-width: 570px;
    width: 90vw;

    display: block ;
    align-items: center;
    margin: auto;
    background: white;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 40px;
    padding-bottom: 40px;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#text {
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    align-content: center;
    min-height: 90px;
}

.line {
    margin: auto;
}

#buttons {
    height: 90px;
    margin: auto;
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}

#buttons > div 
{
    width: 120px;
    margin-left: 20px;
    margin-right: 20px;
}
