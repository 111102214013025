.BaseScreenHeader
{
}

.scs-logo
{
    height: 30px;
}

.screen-menu-loader
{
    position: absolute;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5px;
    width: 80%;
}