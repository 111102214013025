#frame {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

@media print
{
  .no-print, .no-print *
  {
      display: none !important;
      height: 0 !important;
  }

  h1, h2, h3, h4, h5, h6
  {
    text-align: center;
  }

  table
  {
    margin: auto;
  }

  tbody td:first-child
  {
    padding-right: 25px;
  }
  tbody td:last-child
  {
    padding-left: 25px;
  }
}