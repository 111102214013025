.excels
{
    display: flex;
    flex-direction: row;
}

.upload-body
{
    width: 320px;
    height: auto;
    padding: 20px;
    border: 1px;
    border-color: black;
}

.download-body
{
    width: 320px;
    height: auto;
    padding: 20px;
    border: 1px;
    border-color: black;
}

.download-button
{
    height: 75px;
    line-height: 75px;
    border: 1px solid rgb(206, 206, 206);
}

#upload-header
{
    height: 40px;
}

#upload-dropzone
{
    height: 75px;
    line-height: 75px;
    border: 1px solid rgb(206, 206, 206);
}

#upload-dropzone:hover
{
    background: rgb(76, 76, 76);
}
#upload-error
{
    color: #e31d2c;
}

.u-inline-cell
{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}