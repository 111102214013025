.row-captions
{
    height: 0px;
}
.column-data
{
    width: 40%;
}
.column-caption
{
    width: 20%;
}

.race-screen-player-avatar
{
    max-height: 65px;
    max-width: 65px;
    overflow: visible;
    margin: auto;
}

.race-screen-player-avatar img
{
    width: 100%;
    height: 100%;
}