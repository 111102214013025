.tire {
	animation: rotation 3800ms infinite ease-in;
	animation-delay: 200ms;
	transform-origin: 50% 50%;
}	
.rim {
	animation: rotation 3800ms infinite ease-in;
	transform-origin: 50% 50%;
}

.Loader {
	animation: move 3400ms infinite ease-in;
	animation-delay: 400ms;
	width: 100%;
}

@keyframes move {
	0% { transform: translateX(0); }
	100% { transform: translateX(100%); }
}
	
@keyframes rotation {
	from {transform: rotate(0deg);}
	to   {transform: rotate(720deg);}
}