.Button
{
    user-select: none;
    cursor: pointer;
    width: auto;
    position: relative;
}

.Button-normal
{
    display: flex;
    justify-content: space-around;
    padding-left: 25px;
    padding-right: 25px;

    background: #1582ff;
    height: 45px;
    line-height: 42px;
    color: #fff2f9;
    text-align: center;
    border: 0px;
    border-style: solid;
    border-color: #b1313e;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.Button-normal:hover {
    background-color: #c91a29;
}

.Button-normal-disabled
{
    display: flex;
    justify-content: space-around;

    background: rgb(206, 206, 206);
    height: 45px;
    line-height: 45px;
    color: #fff2f9;
    text-align: center;
    cursor: not-allowed;
}

.Button-inverted
{
    display: flex;
    justify-content: space-around;
    height: 45px;
    line-height: 41px;
    color: #1582ff;
    text-align: center;
    border: 2px;
    border-style: solid;
    border-color: #1582ff;

    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
}

.Button-inverted:hover {
    border-color: #c91a29;
    color: #c91a29;
}

.Button-inverted-disabled
{
    display: flex;
    justify-content: space-around;

    background: #fff2f9;
    height: 44px;
    line-height: 44px;
    color: #e3e3e3;
    text-align: center;
    border: 0px;
    border-style: solid;
    border-color: #e3e3e3;
}


@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

#iconLeft
{
    position: absolute;
    left: 0;
    margin-left: 20px;
    animation: rotating 1s linear infinite;

}