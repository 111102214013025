.photo-box-square
{
    position: relative;
    width: 320px;
    height: 400px;
}

.photo-box-container
{
    width: 100%;
    height: 75%;
    display: flex;
    position: relative;
}

.photo-box-avatar
{
    margin: auto;
    width: 40% !important;
    height: 40% !important;
}

.photo-box-picture
{
    margin: auto;
    width: 90% !important;
    height: 90% !important;
    object-fit: cover;
}


.photo-box-edit
{
    position: absolute;
    top: 0px;
    right: -14px;
    font-size: 24px;
}

.photo-box-avatar-clickable
{
    cursor: pointer;
}

.photo-loader
{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 100;
    opacity: 0.75;

    background: lightgray;

    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

.photo-loader-icon
{
    margin: auto;
    width: 25% !important;
    height: 25% !important;
    animation: rotating 2s linear infinite;
}