.movie-box-square
{
    width: 30vw;
    height: 20vw;
}

.movie-box-container
{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.yt-loading
{
    width: 100%;
}