.InputField {
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    width: auto;
    height: 45px;
    border: 2px solid #d7d7d7;
    box-shadow: none;

    display: flex;
    flex-direction: row;

    align-items: center;
}

.InputField input {
    border: none;
    padding: 0px;
    font-size: 14px;
    padding-left: 20px;
}

.InputField:hover {
    border-color: #464646;
}

.InputField-label {
    width: auto;
    display: flex;
    align-items: center !important;
    justify-content: flex-end;
    padding-right: 30px;

}

.InputField-value {
    padding-left: 10px;
    text-align: left;
    flex-basis: auto;
    width: 100%;
    height: 100%;
    flex: 1;
    box-shadow: none;
}


.InputField-error {
    position: absolute;
    right: 20vw;
    z-index: 1;
    text-align: right;
    margin-right: 10px;
    flex-basis: auto;
    width: fit-content;
    flex: 1;
    box-shadow: none;
    background: transparent;
    border-top: transparent !important;
    border-left: transparent !important;
    border-right: transparent !important;
    border-bottom: transparent !important;
}
