.u-section-1 .u-sheet-1 {
    min-height: 575px;
  }
  
  .u-section-1 .u-text-1 {
    margin: 6px auto 0;
  }
  
  .u-section-1 .u-list-1 {
    width: 1140px;
    margin-top: 6px;
    margin-bottom: 50px;
    min-height: 375px;
    grid-template-columns: 33.3333% 33.3333% 33.3333%;
    grid-template-rows: auto;
    grid-gap: 0px 0px;
  }
  
  .u-section-1 .u-container-layout-1 {
    padding: 30px;
  }
  
  .u-section-1 .u-image-1 {
    height: 213px;
    margin: 0 auto 0 0;
  }
  
  .u-section-1 .u-text-2 {
    margin-left: 0;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-line-1 {
    transform-origin: left center;
    margin: 30px auto 0 0;
  }
  
  .u-section-1 .u-text-3 {
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-btn-1 {
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-image: none;
    border-style: none none solid;
    text-transform: uppercase;
    font-weight: 600;
    width: 99px;
    height: 38px;
    margin: 20px auto 0 0;
    padding: 5px 0;
  }
  
  .u-section-1 .u-container-layout-2 {
    padding: 30px;
  }
  
  .u-section-1 .u-image-2 {
    height: 213px;
    margin: 0 auto 0 0;
  }
  
  .u-section-1 .u-text-4 {
    margin-left: 0;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-line-2 {
    transform-origin: left center;
    margin: 30px auto 0 0;
  }
  
  .u-section-1 .u-text-5 {
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-btn-2 {
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-image: none;
    border-style: none none solid;
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 0;
    margin-left: 0;
    width: 99px;
    height: 38px;
    padding: 5px 0;
  }
  
  .u-section-1 .u-container-layout-3 {
    padding: 30px;
  }
  
  .u-section-1 .u-image-3 {
    height: 213px;
    margin: 0 auto 0 0;
  }
  
  .u-section-1 .u-text-6 {
    margin-left: 0;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-line-3 {
    transform-origin: left center;
    margin: 30px auto 0 0;
  }
  
  .u-section-1 .u-text-7 {
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-btn-3 {
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-image: none;
    border-style: none none solid;
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 0;
    margin-left: 0;
    width: 99px;
    height: 38px;
    padding: 5px 0;
  }
  
  .u-section-1 .u-container-layout-4 {
    padding: 30px;
  }
  
  .u-section-1 .u-image-4 {
    height: 213px;
    margin: 0 auto 0 0;
  }
  
  .u-section-1 .u-text-8 {
    margin-left: 0;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-line-4 {
    transform-origin: left center;
    margin: 30px auto 0 0;
  }
  
  .u-section-1 .u-text-9 {
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0;
  }
  
  .u-section-1 .u-btn-4 {
    font-size: 0.875rem;
    letter-spacing: 1px;
    background-image: none;
    border-style: none none solid;
    text-transform: uppercase;
    font-weight: 600;
    margin-right: 0;
    margin-left: 0;
    width: 99px;
    height: 38px;
    padding: 5px 0;
  }
  
  @media (max-width: 1199px) {
    .u-section-1 .u-sheet-1 {
      min-height: 509px;
    }
  
    .u-section-1 .u-list-1 {
      width: 940px;
      min-height: 309px;
      grid-template-columns: repeat(3, 33.333333333333336%);
    }
  
    .u-section-1 .u-image-1 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-line-1 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-image-2 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-line-2 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-image-3 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-line-3 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-image-4 {
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-line-4 {
      margin-right: initial;
      margin-left: initial;
    }
  }
  
  @media (max-width: 991px) {
    .u-section-1 .u-sheet-1 {
      min-height: 555px;
    }
  
    .u-section-1 .u-list-1 {
      width: 720px;
      min-height: 711px;
      grid-template-columns: repeat(2, 50%);
    }
  }
  
  @media (max-width: 767px) {
    .u-section-1 .u-list-1 {
      width: 540px;
      min-height: 1599px;
      grid-template-columns: 100%;
    }
  
    .u-section-1 .u-container-layout-1 {
      padding-top: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .u-section-1 .u-image-1 {
      height: 344px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-text-2 {
      margin-top: 20px;
      margin-right: auto;
    }
  
    .u-section-1 .u-container-layout-2 {
      padding-top: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .u-section-1 .u-image-2 {
      height: 344px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-text-4 {
      margin-top: 20px;
      margin-right: auto;
    }
  
    .u-section-1 .u-btn-2 {
      margin-top: 20px;
      margin-right: auto;
      margin-bottom: 0;
    }
  
    .u-section-1 .u-container-layout-3 {
      padding-top: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .u-section-1 .u-image-3 {
      height: 344px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-text-6 {
      margin-top: 20px;
      margin-right: auto;
    }
  
    .u-section-1 .u-btn-3 {
      margin-top: 20px;
      margin-right: auto;
      margin-bottom: 0;
    }
  
    .u-section-1 .u-container-layout-4 {
      padding-top: 0;
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .u-section-1 .u-image-4 {
      height: 344px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-text-8 {
      margin-top: 20px;
      margin-right: auto;
    }
  
    .u-section-1 .u-btn-4 {
      margin-top: 20px;
      margin-right: auto;
      margin-bottom: 0;
    }
  }
  
  @media (max-width: 575px) {
    .u-section-1 .u-list-1 {
      width: 340px;
      min-height: 1007px;
    }
  
    .u-section-1 .u-image-1 {
      height: 212px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-image-2 {
      height: 212px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-image-3 {
      height: 212px;
      margin-right: initial;
      margin-left: initial;
    }
  
    .u-section-1 .u-image-4 {
      height: 212px;
      margin-right: initial;
      margin-left: initial;
    }
  }