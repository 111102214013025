.react-select-box-container {
    position: relative;
    width: 100%;
    height: 45px;
    display: inline-block;
    background-color: transparent;
    border: 2px solid #d7d7d7;
    text-align: left;
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
.react-select-box-container:hover {
    border-color: #464646
}
  .react-select-box {
    padding: 0 0;
    display: inline-block;
    cursor: pointer;
    border: none;
    height: 100%;
    width: 100%;
    text-align: left;
    background-color: transparent;
  }

  .react-select-box:focus {
    outline: 0;
  }

  /* trojkat */
  .react-select-box:after {
    content: ' ';
    position: absolute;
    z-index: 1;
    top: 20px;
    right: 20px;
    border-top: 4px solid #7B8E9B;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }

  .react-select-box-label,
  .react-select-box-option {
    line-height: 16px;
    font-size: 14px;
    color: #444;
  }

  .react-select-box-label {
    margin-left: 20px;
    /* padding: 0 40px 0 20px;*/
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: #444;
    font-size: 14px;
  }

  .react-select-box-empty .react-select-box-label {
    color: #7B8E9B;
  }

  .react-select-box-click-outside-layer {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }

  .react-select-box-clear {
    position: absolute;
    top: 15px;
    right: 0;
    width: 35px;
    height: 20px;
    background-color: #fff;
    text-indent: -9999em;
    z-index: 3;
    border: none;

  }
  .react-select-box-clear:before {
    content: '×';
    position: absolute;
    top: 2px;
    left: 10px;
    z-index: 1;
    background-color: #7B8E9B;
    border-radius: 100%;
    font-size: 13px;
    color: #fff;
    line-height: 1;
    width: 15px;
    height: 15px;
    text-indent: 0;
    text-align: center;
  }

  .react-select-box-clear:hover,
  .react-select-box-clear:focus {
    outline: 0;
  }

  .react-select-box-clear:hover:before,
  .react-select-box-clear:focus:before {
    background-color: #464646;
  }

  .react-select-box-hidden {
    display: none
  }

  .react-select-box-options {
    margin: 2px 0 0;
    position: absolute;
    padding: 10px 0;
    width: 480px;
    top: 100%;
    left: 0;
    z-index: 4;
    background-color: #fff;
    border-radius: 4px;
  }

  .react-select-box-options-list {
    list-style: none outside;
    margin: 0;
    padding: 0;
  }

  .react-select-box-option {
    padding: 10px 20px;
    margin: 0;
    cursor: pointer;
    display: block;
    line-height: 1.2;
    text-decoration: none;
  }

  .react-select-box-option:hover {
    color: #464646;
    background-color: #f4f4f4;
  }

  .react-select-box-option-selected {
    color: #CBD2D7;
  }

  .react-select-box-multi .react-select-box-option {
    padding-left: 42px;
    position: relative;
  }

  .react-select-box-multi .react-select-box-option:before {
    content: ' ';
    position: absolute;
    line-height: 1;
    text-align: center;
    left: 20px;
    top: 9px;
    border-radius: 3px;
    height: 12px;
    width: 12px;
    margin-right: 10px;
    border: 1px solid #7B8E9B;
    background: #f9f9f9;
    vertical-align: middle;
  }

  .react-select-box-multi .react-select-box-option-selected:before {
    content: '✓';
  }


  .react-select-box-multi .react-select-box-option-selected {
    color: #1F3344;
  }

  .react-select-box-option:focus,
  .react-select-box-option-focused {
    color: #464646;
    outline: 0;
    background-color: #DDE2E5;
  }

  .react-select-box-close {
    color: #464646;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    padding: 5px 0;
    display: block;
    text-align: center;
    width: 100%;
    font-weight: bold;
    cursor: pointer;
    outline: none;
  }

  .react-select-box-close:hover,
  .react-select-box-close:focus {
    text-decoration: underline;
  }

  .react-select-box-empty .react-select-box-close {
    color: #CBD2D7;
  }

  .react-select-box-native {
    position: absolute;
    left: -99999em;
  }
