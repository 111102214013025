.BaseScreen
{
  display: block;
  height: 100%;
}
#overlay {
  z-index: 20;
  cursor: pointer;
  background: white;
  opacity: 0.90;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

#popup {
  /* width: 100%;
  height: 100%; */
  z-index: 20;
  cursor: pointer;
  background: rgba(0, 0, 0, 0.65);
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  /* top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); */
}
